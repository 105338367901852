<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="uc-box uc-main-box">
		<div class="uc-content-box order-list-box">
			<!-- 我的订单头部 -->
			<div class="box-hd">
				<h1 class="title">店铺收藏</h1>
				<div class="clearfix"></div>
			</div>
			<div class="box-bd">
				<div class="merchant">
					<div class="list">
						<div class="product">
							<div class="name">店铺名称</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				orders: [], // 订单列表
				total: [] // 每个订单的商品数量及总价列表
			};
		},
	};
</script>
<style scoped>
	.clearfix {
		clear: both;
	}

	.uc-box {
		background: #fff
	}

	.uc-box .uc-content-box {
		margin: 0 48px
	}

	.uc-box .uc-content-box .box-hd .title {
		margin: 0;
		font-size: 30px;
		font-weight: 400;
		line-height: 68px;
		color: #757575
	}

	.container {
		width: 1226px;
		margin-right: auto;
		margin-left: auto;
	}

	.container:after,
	.container:before {
		content: " ";
		display: table;
	}

	.merchant {
		background-color: #fff;
	}

	.merchant .title {
		height: 56px;
		line-height: 56px;
		font-size: 18px;
		margin: 0 12px;
		border-bottom: 1px solid #eee;
		margin-bottom: 18px;
	}

	.merchant .info {
		display: flex;

	}

	.merchant .infoimg {
		overflow: hidden;
		width: 200px;
		border: 1px solid #eee;
		padding: 5px 10px;
		flex-shrink: 0;
	}

	.merchant .info .img {
		width: 200px;
	}

	.merchant .info .detail {
		padding: 5px 10px;
		border: 1px solid #eee;
		border-left: none;
		font-size: 16px;
		line-height: 30px;
	}

	.merchant .list {
		margin-top: 18px;
		display: flex;
		height: initial;
	}

	.merchant .list .product {
		margin: 0 10px 16px 0;
		width: 200px;
	}

	.merchant .list .product .imgbox {
		width: 200px;
		height: 200px;
		overflow: hidden;
	}

	.merchant .list .product .imgbox .img {
		width: 200px;
	}

	.merchant .list .product .name {
		font-size:20px;
		line-height: 36px;
		color: #000;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		word-break: break-all;
	}

	.merchant .list .product .price {
		font-size: 16px;
		color: #ff0000;
	}
</style>
